import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
	sublist:null,
	subloading:false
}



export const sectionListAllPlant = createAsyncThunk('plant/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await clientService.getPlant(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const sectionListAll = createAsyncThunk('section/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await clientService.getSection(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const sectionSubListAll = createAsyncThunk('section/sublist',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await clientService.getSection(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const sectionSlice = createSlice({
	name: 'section',
	initialState,
	reducers: {
			
		sectionList: (state, action) => {
			state.loading = true
			state.list = action.payload
		},
		sectionSubList: (state, action) => {
			state.subloading = true
			state.sublist = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(sectionListAllPlant.pending, (state) => {
		
			state.loading = false
			state.subloading = false
		})
		.addCase(sectionListAllPlant.fulfilled, (state, action) => {
			state.loading = true
			state.subloading = true
			state.list = action.payload
		})	
		.addCase(sectionListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(sectionListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			.addCase(sectionSubListAll.pending, (state) => {
		
				state.subloading = false
			})
			.addCase(sectionSubListAll.fulfilled, (state, action) => {
				state.subloading = true
				state.sublist = action.payload
			})
			
	},
})

export const { 
	sectionList, sectionSubList
    
    
} = sectionSlice.actions

export default sectionSlice.reducer