import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const employeeListAll = createAsyncThunk('employee/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await clientService.getEmployee()
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const employeeSlice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
			
		employeeList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(employeeListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(employeeListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	employeeList,
    
    
} = employeeSlice.actions

export default employeeSlice.reducer