import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const pmCheckListAll = createAsyncThunk('pmchecklist/list',async (data, { rejectWithValue }) => {
	
	
	try {
		const response = await clientService.getPmCheckList(data)
		
        return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const pmcheckListSlice = createSlice({
	name: 'pmchecklist',
	initialState,
	reducers: {
			
		pmCheckList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(pmCheckListAll.pending, (state) => {
                
				state.loading = false
			})
			.addCase(pmCheckListAll.fulfilled, (state, action) => {
				
                state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	pmCheckList,
    
    
} = pmcheckListSlice.actions

export default pmcheckListSlice.reducer