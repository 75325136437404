import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}

export const rcaDocumentListAll = createAsyncThunk('rcaDocument/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await clientService.getRcaDocument()
		return response.data;		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const rcaDocumentSlice = createSlice({
	name: 'rcaDocument',
	initialState,
	reducers: {
			
		rcaDocumentList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(rcaDocumentListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(rcaDocumentListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})			
	},
})

export const { 
	rcaDocumentList,   
    
} = rcaDocumentSlice.actions

export default rcaDocumentSlice.reducer