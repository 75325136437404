import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
	sublist:null,
	subloading:false
}




export const categoryListAll = createAsyncThunk('category/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await clientService.getCategory(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const categorySubListAll = createAsyncThunk('category/sublist',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await clientService.getCategory(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
			
		categoryList: (state, action) => {
			state.loading = true
			state.list = action.payload
		},
		categorySubList: (state, action) => {
			state.subloading = true
			state.sublist = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
		.addCase(categoryListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(categoryListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			.addCase(categorySubListAll.pending, (state) => {
		
				state.subloading = false
			})
			.addCase(categorySubListAll.fulfilled, (state, action) => {
				state.subloading = true
				state.sublist = action.payload
			})
			
	},
})

export const { 
	categoryList, categorySubList
    
    
} = categorySlice.actions

export default categorySlice.reducer