import fetch from 'auth/FetchMasterInterceptor'
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

const clientService = {}

clientService.getClient = function (data) {
  return fetch({
    url: '/client/list',
    method: 'post',
    data:data
  })
}
clientService.getSetting = function (data) {
  return fetch({
    url: '/setting',
    method: 'post',
    data:data
  })
}


clientService.addClient = function (data) {
  return fetch({
    url: '/client/create',
    method: 'post',
    data:data
  })
}
clientService.editClient = function (data) {
  return fetch({
    url: '/setting/update',
    method: 'post',
    data:data
  })
}
clientService.deleteClient = function (data) {
  return fetch({
    url: '/client/delete',
    method: 'post',
    data:data
  })
}

clientService.getCompany = function (data) {
  return fetch({
    url: '/company/list',
    method: 'post',
    data:data
  })
}
clientService.addCompany = function (data) {
  return fetch({
    url: '/company/create',
    method: 'post',
    data:data
  })
}
clientService.editCompany = function (data) {
  return fetch({
    url: '/company/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteCompany = function (data) {
  return fetch({
    url: '/company/delete',
    method: 'post',
    data:data
  })
}
clientService.viewCompany = function (data) {
  return fetch({
    url: '/company/view',
    method: 'post',
    data:data
  })
}

clientService.addSection = function (data) {
  return fetch({
    url: '/section/create',
    method: 'post',
    data:data
  })
}
clientService.editSection = function (data) {
  return fetch({
    url: '/section/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteSection = function (data) {
  return fetch({
    url: '/section/delete',
    method: 'post',
    data:data
  })
}
clientService.getSection = function (data) {
  return fetch({
    url: '/section/list',
    method: 'post',
    data:data
  })
}
clientService.getPlant = function (data) {
  return fetch({
    url: '/plant/list',
    method: 'post',
    data:data
  })
}
clientService.addPlant = function (data) {
  return fetch({
    url: '/plant/create',
    method: 'post',
    data:data
  })
}
clientService.editPlant = function (data) {
  return fetch({
    url: '/plant/edit',
    method: 'post',
    data:data
  })
}
clientService.deletePlant = function (data) {
  return fetch({
    url: '/plant/delete',
    method: 'post',
    data:data
  })
}
clientService.viewPlant = function (data) {
  return fetch({
    url: '/plant/view',
    method: 'post',
    data:data
  })
}
clientService.getEmployee = function (data) {
  return fetch({
    url: '/employee/list',
    method: 'post',
    data:data
  })
}
clientService.addEmployee = function (data) {
  return fetch({
    url: '/employee/create',
    method: 'post',
    data:data
  })
}
clientService.editEmployee = function (data) {
  return fetch({
    url: '/employee/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteEmployee = function (data) {
  return fetch({
    url: '/employee/delete',
    method: 'post',
    data:data
  })
}
clientService.viewEmployee = function (data) {
  return fetch({
    url: '/employee/view',
    method: 'post',
    data:data
  })
}
clientService.addCategory = function (data) {
  return fetch({
    url: '/category/create',
    method: 'post',
    data:data
  })
}
clientService.editCategory = function (data) {
  return fetch({
    url: '/category/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteCategory = function (data) {
  return fetch({
    url: '/category/delete',
    method: 'post',
    data:data
  })
}
clientService.getCategory = function (data) {
  return fetch({
    url: '/category/list',
    method: 'post',
    data:data
  })
}
clientService.addEquipment = function (data) {
  return fetch({
    url: '/equipment/create',
    method: 'post',
    data:data
  })
}
clientService.editEquipment = function (data) {
  return fetch({
    url: '/equipment/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteEquipment = function (data) {
  return fetch({
    url: '/equipment/delete',
    method: 'post',
    data:data
  })
}
clientService.getEquipment = function (data) {
  return fetch({
    url: '/equipment/list',
    method: 'post',
    data:data
  })
}
clientService.addProcess = function (data) {
  return fetch({
    url: '/process/create',
    method: 'post',
    data:data
  })
}
clientService.editProcess = function (data) {
  return fetch({
    url: '/process/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteProcess = function (data) {
  return fetch({
    url: '/process/delete',
    method: 'post',
    data:data
  })
}
clientService.getProcess = function (data) {
  return fetch({
    url: '/process/list',
    method: 'post',
    data:data
  })
}
clientService.addPlantEquipment = function (data) {
  return fetch({
    url: '/plantEquipment/create',
    method: 'post',
    data:data
  })
}
clientService.editPlantEquipment = function (data) {
  return fetch({
    url: '/plantEquipment/edit',
    method: 'post',
    data:data
  })
}
clientService.deletePlantEquipment = function (data) {
  return fetch({
    url: '/plantEquipment/delete',
    method: 'post',
    data:data
  })
}
clientService.getPlantEquipment = function (data) {
  return fetch({
    url: '/plantEquipment/list',
    method: 'post',
    data:data
  })
}
clientService.viewPlantEquipment = function (data) {
  return fetch({
    url: '/plantEquipment/view',
    method: 'post',
    data:data
  })
}
clientService.addCheckList = function (data) {
  return fetch({
    url: '/checklist/create',
    method: 'post',
    data:data
  })
}
clientService.editCheckList = function (data) {
  return fetch({
    url: '/checklist/edit',
    method: 'post',
    data:data
  })
}
clientService.getCheckList = function (data) {
  return fetch({
    url: '/checklist/list',
    method: 'post',
    data:data
  })
}
clientService.viewCheckList = function (data) {
  return fetch({
    url: '/checklist/view',
    method: 'post',
    data:data
  })
}
clientService.deleteCheckList = function (data) {
  return fetch({
    url: '/checklist/delete',
    method: 'post',
    data:data
  })
}
clientService.addPmCheckList = function (data) {
  /*  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization":`Bearer ${jwtToken}`
    },
  };
  
  return axios
    .post(`${API_BASE_URL}/pmchecklist/create`, data, config);
  */
    
   return fetch({
    url: '/pmchecklist/create',
    method: 'post',
    data:data
  })
  
  
}
clientService.getPmCheckList = function (data) {
  return fetch({
    url: '/pmchecklist/list',
    method: 'post',
    data:data
  })
}
clientService.viewPmCheckList = function (data) {
  return fetch({
    url: '/pmchecklist/view',
    method: 'post',
    data:data
  })
}
clientService.deletePmCheckList = function (data) {
  return fetch({
    url: '/pmchecklist/delete',
    method: 'post',
    data:data
  })
}
clientService.editPmCheckList = function (data) {
  return fetch({
    url: '/pmchecklist/edit',
    method: 'post',
    data:data
  })
}
clientService.searchPmCheckList = function (data) {
  return fetch({
    url: '/checklist/search',
    method: 'post',
    data:data
  })
}
clientService.getBreakDownList = function (data) {
  return fetch({
    url: '/breakDownTicket/list',
    method: 'post',
    data:data
  })
}
clientService.viewBreakDownList = function (data) {
  return fetch({
    url: '/breakDownTicket/view',
    method: 'post',
    data:data
  })
}
clientService.addBreakDownList = function (data) {
  return fetch({
    url: '/breakDownTicket/create',
    method: 'post',
    data:data
  })
}
clientService.editBreakDownList = function (data) {
  return fetch({
    url: '/breakDownTicket/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteBreakDownList = function (data) {
  return fetch({
    url: '/breakDownTicket/delete',
    method: 'post',
    data:data
  })
}

clientService.getBreakDownLog = function (data) {
  return fetch({
    url: '/breakDownLog/list',
    method: 'post',
    data:data
  })
}
clientService.viewBreakDownLog = function (data) {
  return fetch({
    url: '/breakDownLog/view',
    method: 'post',
    data:data
  })
}
clientService.addBreakDownLog = function (data) {
  return fetch({
    url: '/breakDownLog/create',
    method: 'post',
    data:data
  })
}
clientService.editBreakDownLog = function (data) {
  return fetch({
    url: '/breakDownLog/edit',
    method: 'post',
    data:data
  })
}
clientService.deleteBreakDownLog = function (data) {
  return fetch({
    url: '/breakDownLog/delete',
    method: 'post',
    data:data
  })
}

clientService.getRcaDocument = function (data) {
  return fetch({
    url: '/rcaDocument/list',
    method: 'post',
    data:data
  })
}
clientService.addRcaDocument = function (data) {
  return fetch({
    url: '/rcaDocument/create',
    method: 'post',
    data:data
  })
}
clientService.deleteRcaDocument = function (data) {
  return fetch({
    url: '/rcaDocument/delete',
    method: 'post',
    data:data
  })
}
clientService.editRcaDocument = function (data) {
  return fetch({
    url: '/rcaDocument/edit',
    method: 'post',
    data:data
  })
}
clientService.viewRcaDocument = function (data) {
  return fetch({
    url: '/rcaDocument/view',
    method: 'post',
    data:data
  })
}

clientService.getIncidentReport = function (data) {
  return fetch({
    url: '/IncidentReport/list',
    method: 'post',
    data:data
  })
}
clientService.addIncidentReport = function (data) {
  return fetch({
    url: '/IncidentReport/create',
    method: 'post',
    data:data
  })
}
clientService.deleteIncidentReport = function (data) {
  return fetch({
    url: '/IncidentReport/delete',
    method: 'post',
    data:data
  })
}
clientService.editIncidentReport = function (data) {
  return fetch({
    url: '/IncidentReport/edit',
    method: 'post',
    data:data
  })
}
clientService.viewIncidentReport = function (data) {
  return fetch({
    url: '/IncidentReport/view',
    method: 'post',
    data:data
  })
}

clientService.getDashboard = function (data) {
  return fetch({
    url: '/dashboard',
    method: 'post',
    data:data
  })
}

    
export default clientService