import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const plantEquipemntListAll = createAsyncThunk('plantequipment/list',async (data, { rejectWithValue }) => {
	
	try {
		const response = await clientService.getPlantEquipment()
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const plantEquipemntSlice = createSlice({
	name: 'plantequipment',
	initialState,
	reducers: {
			
		plantEquipemntList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(plantEquipemntListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(plantEquipemntListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	plantEquipemntList,
    
    
} = plantEquipemntSlice.actions

export default plantEquipemntSlice.reducer