import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import company from './slices/companySlice'
import section from './slices/sectionSlice'
import plant from './slices/plantSlice'
import master from './slices/masterSlice'
import employee from './slices/employeeSlice'
import category from './slices/categorySlice'
import equipment from './slices/equipmentSlice'
import process from './slices/processSlice'
import plantequipment from './slices/plantEquipmentSlice'
import pmchecklist from './slices/pmchecklistSlice'
import checklist from './slices/checklistSlice'
import breakdown from './slices/breakdownSlice'
import breakdownlog from './slices/breakdownlogSlice'
import rcadocument from './slices/rcaDocumentSlice'
import incident  from './slices/incidentReportSlice'


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        company,
        section,
        plant,
        master,
        employee,
        category,
        equipment,
        process,
        plantequipment,
        pmchecklist,
        checklist,
        breakdown,
        breakdownlog,
        rcadocument,
        incident,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
