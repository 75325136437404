import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import masterService from 'services/MasterService';
import { useDispatch } from 'react-redux';



export const initialState = {
	deploading: false,
	message: '',
	deplist:null,
	desglist:null,
	desgloading:false,
    freqlist:null,
	freqloading:false,
}



export const departmentListAll = createAsyncThunk('department/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await masterService.getDepartment(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const designationListAll = createAsyncThunk('designation/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await masterService.getDesignation(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const frequencyListAll = createAsyncThunk('frequency/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await masterService.getFrequency(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const masterSlice = createSlice({
	name: 'master',
	initialState,
	reducers: {
			
		departmentList: (state, action) => {
			state.deploading = true
			state.deplist = action.payload
		},
		designationList: (state, action) => {
			state.desgloading = true
			state.desglist = action.payload
		},
        frequencyList: (state, action) => {
			state.freqloading = true
			state.freqlist = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(departmentListAll.pending, (state) => {
		
				state.deploading = false
			})
			.addCase(departmentListAll.fulfilled, (state, action) => {
				state.deploading = true
				state.deplist = action.payload
			})
			.addCase(designationListAll.pending, (state) => {
		
				state.desgloading = false
			})
			.addCase(designationListAll.fulfilled, (state, action) => {
				state.desgloading = true
				state.desglist = action.payload
			})
            .addCase(frequencyListAll.pending, (state) => {
		
				state.freqloading = false
			})
			.addCase(frequencyListAll.fulfilled, (state, action) => {
				state.freqloading = true
				state.freqlist = action.payload
			})
			
	},
})

export const { 
	departmentList, designationList, frequencyList
    
    
} = masterSlice.actions

export default masterSlice.reducer