import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const breakdownListAll = createAsyncThunk('breakdownlist/list',async (data, { rejectWithValue }) => {
	
	
	try {
		const response = await clientService.getBreakDownList(data)
		
        return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const breakdownListSlice = createSlice({
	name: 'breakdown',
	initialState,
	reducers: {
			
		breakdownList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(breakdownListAll.pending, (state) => {
                
				state.loading = false
			})
			.addCase(breakdownListAll.fulfilled, (state, action) => {
				
                state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	breakdownList,
    
    
} = breakdownListSlice.actions

export default breakdownListSlice.reducer