import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';



export const initialState = {
	loading: false,
	message: '',
	list:null,
	
}




export const equipmentListAll = createAsyncThunk('equipment/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await clientService.getEquipment(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})



export const equipmentSlice = createSlice({
	name: 'equipment',
	initialState,
	reducers: {
			
		equipmentList: (state, action) => {
			state.loading = true
			state.list = action.payload
		},
		
	},
	extraReducers: (builder) => {
		builder
		.addCase(equipmentListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(equipmentListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
			
	},
})

export const { 
	equipmentList
    
    
} = equipmentSlice.actions

export default equipmentSlice.reducer