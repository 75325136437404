import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from "constants/AuthConstant";
import clientService from "../../services/ClientService";
import { useDispatch } from "react-redux";

export const initialState = {
  loading: false,
  message: "",
  list: null,
};

export const incidentReportListAll = createAsyncThunk(
  "IncidentReport/list",
  async (data, { rejectWithValue }) => {
    //  console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",data);
    try {
      const response = await clientService.getIncidentReport();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    incidentList: (state, action) => {
      state.loading = true;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(incidentReportListAll.pending, (state) => {
        state.loading = false;
      })
      .addCase(incidentReportListAll.fulfilled, (state, action) => {
        state.loading = true;
        state.list = action.payload;
      });
  },
});

export const { incidentList } = incidentSlice.actions;

export default incidentSlice.reducer;
